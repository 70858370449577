import { IConfigsType } from '../../types'

const fwWingstopFlashSalesAndTicketCompReport: IConfigsType = {
  fw_wingstop: {
    '/:brand/sales_and_ticket_comp': {
      groupFilter: {
        api: 'corporateFilter',
        defaultValue: ['Market', 'Market Breakdown'],
      },
      dateFilter: {
        types: ['yesterday', 'custom'],
        defaultType: 'yesterday',
        isDailyCustom: true,
      },
      reportTitle: {
        type: 'title',
        title: 'Sales and Ticket Comp Report',
      },
      downloadSalesReport: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-sales-and-ticket-comp-report',
        fileNameFn: (date: string) =>
          `getexpo-far-west-sales-and-ticket-comp-report-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          // {
          //   key: 'market',
          //   title: 'Market',
          // },
          // {
          //   key: 'openAt',
          //   title: 'Open Day',
          // },
          {
            key: 'netSales',
            title: 'Sales',
          },
          {
            key: 'netSalesSameDayLastYear',
            title: 'Sales same day Last Year',
          },
          {
            key: 'netSalesSameDay2YearsAgo',
            title: 'Sales same day Last 2 Years',
          },
          {
            key: 'netSalesWtd',
            title: 'Sales WTD',
          },
          {
            key: 'comparableNetSalesWtdLy',
            title: 'Sales WTD Last Year',
          },
          {
            key: 'averageNetSalesTrailing7Days',
            title: 'Sales Avg T7',
          },
          {
            key: 'averageNetSalesTrailing7DaysLastYear',
            title: 'Sales Avg T7 Last Year',
          },
          {
            key: 'netSales7DaysAgo',
            title: 'Sales Prior Week',
          },
          {
            key: 'netSalesQtd',
            title: 'Sales QTD',
          },
          {
            key: 'netSalesYtd',
            title: 'Sales YTD',
          },
          {
            key: 'netSalesTtm',
            title: 'Sales TTM',
          },
          {
            key: 'netSalesPercentageDiffToLastYear',
            title: 'Comp Sales Daily (1-yr)',
          },
          {
            key: 'netSalesPercentageDiffTo2YearsAgo',
            title: 'Comp Sales Daily (2-yr)',
          },
          {
            key: 'netSalesPercentageDiffTo7DaysAgo',
            title: 'Comp Sales WoW',
          },
          {
            key: 'averageNetSalesTrailing7DaysPercentageDiffToLastYear',
            title: 'Comp Sales T7',
          },
          {
            key: 'netSalesLastWeekPercentageDiffToLastYear',
            title: 'Comp Sales P-Wk',
          },
          {
            key: 'netSalesWtdPercentageDiffToLastYear',
            title: 'Comp Sales WTD',
          },
          {
            key: 'netSalesPtdPercentageDiffToLastYear',
            title: 'Comp Sales PTD',
          },
          {
            key: 'netSalesTrailing30DaysPercentageDiffToLastYear',
            title: 'Comp Sales T30',
          },
          {
            key: 'netSalesQtdPercentageDiffToLastYear',
            title: 'Comp Sales QTD',
          },
          {
            key: 'netSalesYtdPercentageDiffToLastYear',
            title: 'Comp Sales YTD',
          },
          {
            key: 'checkCount',
            title: 'Tickets',
          },
          {
            key: 'checkCount7DaysAgo',
            title: 'Tickets Prior Week',
          },
          {
            key: 'checkCountQtd',
            title: 'Tickets QTD',
          },
          {
            key: 'checkCountSameDayLastYear',
            title: 'Tickets Prior Year',
          },
          {
            key: 'checkCountSameDay2YearsAgo',
            title: 'Tickets Prior 2 Years',
          },
          {
            key: 'checkCountPercentageDiffToLastYear',
            title: 'Comp Tickets Daily (1-yr)',
          },
          {
            key: 'checkCountPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets Daily (2-yr)',
          },
          {
            key: 'checkCountPercentageDiffTo7DaysAgo',
            title: 'Comp Tickets WoW',
          },
          {
            key: 'averageCheckCountTrailing7DaysPercentageDiffToLastYear',
            title: 'Comp Tickets T7',
          },
          {
            key: 'checkCountLastWeekPercentageDiffToLastYear',
            title: 'Comp Tickets P-Wk',
          },
          {
            key: 'checkCountWtdPercentageDiffToLastYear',
            title: 'Comp Tickets WTD',
          },
          {
            key: 'checkCountPtdPercentageDiffToLastYear',
            title: 'Comp Tickets PTD',
          },
          {
            key: 'checkCountTrailing30DaysPercentageDiffToLastYear',
            title: 'Comp Tickets T30',
          },
          {
            key: 'checkCountQtdPercentageDiffToLastYear',
            title: 'Comp Tickets QTD',
          },
          {
            key: 'checkCountYtdPercentageDiffToLastYear',
            title: 'Comp Tickets YTD',
          },
          {
            key: 'checkAverage',
            title: 'Checks',
          },
          {
            key: 'checkAverageLastWeek',
            title: 'Checks Prior Week',
          },
          {
            key: 'checkAverageQtd',
            title: 'Checks QTD',
          },
          {
            key: 'checkAverageSameDayLastYear',
            title: 'Checks Prior Year',
          },
          {
            key: 'checkAverageSameDay2YearsAgo',
            title: 'Checks Prior 2 Years',
          },
          {
            key: 'checkAveragePercentageDiffToLastYear',
            title: 'Comp Checks Daily (1-yr)',
          },
          {
            key: 'checkAveragePercentageDiffTo2YearsAgo',
            title: 'Comp Checks Daily (2-yr)',
          },
          {
            key: 'checkAveragePercentageDiffTo7DaysAgo',
            title: 'Comp Checks WoW',
          },
          {
            key: 'checkAverageTrailing7DaysPercentageDiffToLastYear',
            title: 'Comp Checks T7',
          },
          {
            key: 'checkAverageLastWeekPercentageDiffToLastYear',
            title: 'Comp Checks P-Wk',
          },
          {
            key: 'checkAverageWtdPercentageDiffToLastYear',
            title: 'Comp Checks WTD',
          },
          {
            key: 'checkAveragePtdPercentageDiffToLastYear',
            title: 'Comp Checks PTD',
          },
          {
            key: 'checkAverageTrailing30DaysPercentageDiffToLastYear',
            title: 'Comp Checks T30',
          },
          {
            key: 'checkAverageQtdPercentageDiffToLastYear',
            title: 'QTD',
          },
          {
            key: 'checkAverageYtdPercentageDiffToLastYear',
            title: 'Comp Checks YTD',
          },
        ],
      },
      salesReportTableSales: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          // {
          //   key: 'market',
          //   title: 'Market',
          //   highlight: true,
          //   sortable: true,
          // },
          // {
          //   key: 'openAt',
          //   title: 'Open Day',
          //   width: '100px',
          //   sortable: true,
          // },
          {
            key: 'sales',
            title: 'Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: 'Sales',
                sortable: true,
              },
              {
                key: 'netSalesSameDayLastYear',
                title: 'Sales same day Last Year',
                sortable: true,
              },
              // {
              //   key: 'netSalesSameDay2YearsAgo',
              //   title: 'Sales same day Last 2 Years',
              //   sortable: true,
              // },
              // {
              //   key: 'netSalesWtd',
              //   title: 'Sales WTD',
              //   sortable: true,
              // },
              // {
              //   key: 'comparableNetSalesWtdLy',
              //   title: 'Sales WTD Last Year',
              //   sortable: true,
              // },
              {
                key: 'averageNetSalesTrailing7Days',
                title: 'Sales Avg T7',
                sortable: true,
              },
              {
                key: 'averageNetSalesTrailing7DaysLastYear',
                title: 'Sales Avg T7 Last Year',
                sortable: true,
              },
              {
                key: 'netSales7DaysAgo',
                title: 'Sales Prior Week',
                sortable: true,
              },
              // {
              //   key: 'netSalesQtd',
              //   title: 'Sales QTD',
              //   sortable: true,
              // },
              // {
              //   key: 'netSalesYtd',
              //   title: 'Sales YTD',
              //   sortable: true,
              // },
              {
                key: 'netSalesTtm',
                title: 'Sales TTM',
                sortable: true,
              },
              {
                key: 'netSalesPercentageDiffTo7DaysAgo',
                title: 'WoW',
                sortable: true,
              },
            ],
          },
          {
            key: 'compSales',
            title: 'Comp Sales',
            align: 'center',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYear',
                title: 'Daily (1-yr)',
                sortable: true,
              },
              // {
              //   key: 'netSalesPercentageDiffTo2YearsAgo',
              //   title: 'Daily (2-yr)',
              //   sortable: true,
              // },
              {
                key: 'averageNetSalesTrailing7DaysPercentageDiffToLastYear',
                title: 'T7',
                sortable: true,
              },
              {
                key: 'netSalesLastWeekPercentageDiffToLastYear',
                title: 'P-Wk',
                sortable: true,
              },
              {
                key: 'netSalesWtdPercentageDiffToLastYear',
                title: 'WTD',
                sortable: true,
              },
              {
                key: 'netSalesPtdPercentageDiffToLastYear',
                title: 'PTD',
                sortable: true,
              },
              // {
              //   key: 'netSalesTrailing30DaysPercentageDiffToLastYear',
              //   title: 'T30',
              //   sortable: true,
              // },
              {
                key: 'netSalesQtdPercentageDiffToLastYear',
                title: 'QTD',
                sortable: true,
              },
              {
                key: 'netSalesYtdPercentageDiffToLastYear',
                title: 'YTD',
                sortable: true,
              },
            ],
          },
        ],
      },
      salesReportTableTickets: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          // {
          //   key: 'market',
          //   title: 'Market',
          //   highlight: true,
          //   sortable: true,
          // },
          // {
          //   key: 'openAt',
          //   title: 'Open Day',
          //   width: '100px',
          //   sortable: true,
          // },
          {
            key: 'tickets',
            title: 'Tickets',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'checkCount',
                title: 'Tickets',
                sortable: true,
              },
              {
                key: 'checkCount7DaysAgo',
                title: 'Tickets Prior Week',
                sortable: true,
                align: 'center',
              },
              // {
              //   key: 'checkCountQtd',
              //   title: 'Tickets QTD',
              //   sortable: true,
              // },
              {
                key: 'checkCountSameDayLastYear',
                title: 'Tickets Prior Year',
                sortable: true,
                align: 'center',
              },
              // {
              //   key: 'checkCountSameDay2YearsAgo',
              //   title: 'Tickets Prior 2 Years',
              //   sortable: true,
              // },
              {
                key: 'checkCountPercentageDiffTo7DaysAgo',
                title: 'WoW',
                sortable: true,
              },
            ],
          },
          {
            key: 'compTickets',
            title: 'Comp Tickets',
            align: 'center',
            children: [
              {
                key: 'checkCountPercentageDiffToLastYear',
                title: 'Daily (1-yr)',
                sortable: true,
                align: 'center',
              },
              // {
              //   key: 'checkCountPercentageDiffTo2YearsAgo',
              //   title: 'Daily (2-yr)',
              //   sortable: true,
              // },
              // {
              //   key: 'averageCheckCountTrailing7DaysPercentageDiffToLastYear',
              //   title: 'T7',
              //   sortable: true,
              // },
              {
                key: 'checkCountLastWeekPercentageDiffToLastYear',
                title: 'P-Wk',
                sortable: true,
              },
              {
                key: 'checkCountWtdPercentageDiffToLastYear',
                title: 'WTD',
                sortable: true,
              },
              {
                key: 'checkCountPtdPercentageDiffToLastYear',
                title: 'PTD',
                sortable: true,
              },
              // {
              //   key: 'checkCountTrailing30DaysPercentageDiffToLastYear',
              //   title: 'T30',
              //   sortable: true,
              // },
              {
                key: 'checkCountQtdPercentageDiffToLastYear',
                title: 'QTD',
                sortable: true,
              },
              {
                key: 'checkCountYtdPercentageDiffToLastYear',
                title: 'YTD',
                sortable: true,
              },
            ],
          },
        ],
      },
      salesReportTableChecks: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          // {
          //   key: 'market',
          //   title: 'Market',
          //   highlight: true,
          //   sortable: true,
          // },
          // {
          //   key: 'openAt',
          //   title: 'Open Day',
          //   width: '100px',
          //   sortable: true,
          // },
          {
            key: 'checks',
            title: 'Checks',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'checkAverage',
                title: 'Checks',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'checkAverageLastWeek',
                title: 'Checks Prior Week',
                decimal: 2,
                sortable: true,
                align: 'center',
              },
              // {
              //   key: 'checkAverageQtd',
              //   title: 'Checks QTD',
              //   decimal: 2,
              //   sortable: true,
              // },
              {
                key: 'checkAverageSameDayLastYear',
                title: 'Checks Prior Year',
                decimal: 2,
                sortable: true,
                align: 'center',
              },
              // {
              //   key: 'checkAverageSameDay2YearsAgo',
              //   title: 'Checks Prior 2 Years',
              //   decimal: 2,
              //   sortable: true,
              // },
              {
                key: 'checkAveragePercentageDiffTo7DaysAgo',
                title: 'WoW',
                sortable: true,
              },
            ],
          },
          {
            key: 'compChecks',
            title: 'Comp Checks',
            align: 'center',
            children: [
              {
                key: 'checkAveragePercentageDiffToLastYear',
                title: 'Daily (1-yr)',
                sortable: true,
                align: 'center',
              },
              // {
              //   key: 'checkAveragePercentageDiffTo2YearsAgo',
              //   title: 'Daily (2-yr)',
              //   sortable: true,
              // },
              // {
              //   key: 'checkAverageTrailing7DaysPercentageDiffToLastYear',
              //   title: 'T7',
              //   sortable: true,
              // },
              {
                key: 'checkAverageLastWeekPercentageDiffToLastYear',
                title: 'P-Wk',
                sortable: true,
              },
              {
                key: 'checkAverageWtdPercentageDiffToLastYear',
                title: 'WTD',
                sortable: true,
              },
              {
                key: 'checkAveragePtdPercentageDiffToLastYear',
                title: 'PTD',
                sortable: true,
              },
              // {
              //   key: 'checkAverageTrailing30DaysPercentageDiffToLastYear',
              //   title: 'T30',
              //   sortable: true,
              // },
              {
                key: 'checkAverageQtdPercentageDiffToLastYear',
                title: 'QTD',
                sortable: true,
              },
              {
                key: 'checkAverageYtdPercentageDiffToLastYear',
                title: 'YTD',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashSalesAndTicketCompReport
